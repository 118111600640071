<template>
  <div class="container">
    <div class="item" :style="{backgroundImage: `url(${item.backgroundImageUrl})`}" v-for="item in hospitalList"
      :key="item.hospitalId" @click="jump(item.hospitalCode)">
      <div class="hospital-name">{{item.hospitalName}}</div>
      <div class="hospital-address">{{item.hospitalAddress}}</div>
      <div class="botton">立即预约 ></div>
    </div>
    <van-empty description="暂无开通医院" v-if="isShowEmpty" />
  </div>
</template>

<script>

import { GetGroupHospital } from '@/api/index'

export default {
  name: "index",
  data() {
    return {
      hospitalList: [],
      isShowEmpty: false
    };
  },
  created() {
    const id = this.$route.params.id
    this.init(id)
    // this.$setTitle('分院选择')
    this.$store.commit('setTitle', '分院选择')
  },
  methods: {
    async init(id) {
      const res = await GetGroupHospital({
        hospitalGroupId: id
      })
      if (res && res.length) {
        this.hospitalList = res
      } else {
        this.isShowEmpty = true
      }
    },
    jump(hospitalCode) {
      this.$router.push('/home?hospitalId=' + hospitalCode)
    },
  }

};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;
  background-color: #f5f5f5;
}

.item {
  position: relative;
  padding: 20px 92px 20px 15px;
  margin-bottom: 10px;
  width: 100%;
  height: 144px;
  border-radius: 8px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #00c4b3;
}

.hospital-name {
  font-size: 18px;
  color: #fff;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.hospital-address {
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

}

.botton {
  position: absolute;
  left: 15px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 24px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #FFFFFF;
  border-radius: 12px;
}
</style>